@import './inter.css';
@import 'aos/dist/aos.css';
@import './tailwind.css';

a {
  color: inherit;
  text-decoration: none;
}

.app-region-drag {
  -webkit-app-region: drag;
}

.border-rainbow {
  padding: 1px;
  background: #0a0a11;
  background-clip: content-box, border-box;
  background-image: linear-gradient(#0a0a11, #0a0a11),
    linear-gradient(90deg, #ec6192, #ec4c34, #ffbd2b, #ebde56, #57c754, #53a1eb);
}

.backlink-html-context h1,
.backlink-html-context h2,
.backlink-html-context h3 {
  margin: 0em 0 0.3em 0 !important;
}

.backlink-html-context p {
  margin: 0.5rem 0 !important;
}

.backlink-html-context ul,
.backlink-html-context ul p {
  margin: 0rem !important;
}

.prose li p {
  margin-top: 0;
  margin-bottom: 0;
}

.expandable-unordered-list > .expandable-list-item::before {
  display: none;
}

.expandable-list-item-handle {
  display: none;
}

.expandable-unordered-list > .expandable-list-item > .expandable-list-item-handle {
  display: block;
}

.expandable-list-item-collapsed > *:not(.expandable-list-item-handle):not(p) {
  display: none;
}

.expandable-unordered-list-spine {
  display: none;
}

.expandable-list-item .expandable-unordered-list-spine {
  display: block;
}

.btn,
.btn-sm {
  @apply text-lg font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-200 ease-in-out cursor-pointer;
}

.btn {
  @apply px-8 py-4 shadow-lg;
}

.btn-sm {
  @apply px-4 py-2 shadow text-base;
}

.blur {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.shortcuts table {
  margin: 0;
}

.shortcuts tbody tr {
  @apply border-gray-100 dark:border-gray-900;
}

.shortcuts tbody td {
  @apply align-middle;
}

.themed-loading-indicator {
  @apply bg-white;
}

@media (prefers-color-scheme: dark) {
  .themed-loading-indicator {
    @apply bg-black;
  }
}

.shadow-outline-purple {
  @apply ring ring-opacity-50 ring-purple-500;
}
